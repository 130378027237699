body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: Arial, sans-serif;
  background-color: #f8f8f8;
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  /* Базовый размер шрифта */
  font-size: 16px;

  /* Отступы кнопок */
  --button-padding-small: 0.375rem 0.75rem; /* 6px 12px */
  --button-padding-medium: 0.5rem 1rem; /* 8px 16px */
  --button-padding-large: 0.75rem 1.5rem; /* 12px 24px */

  --button-margin-small: 0.25rem 0.25rem; /* Маленькие отступы */
  --button-margin-medium: 0.75rem 0.75rem; /* Средние отступы */
  --button-margin-minimal: 0rem 0rem; /* Большие отступы */
  
  /* Цвета кнопок */
  --BLUE_BUTTON: #1E90FF;
  --BLUE_BUTTON_DARK: #007bff;
  --GREEN_BUTTON: #4CAF50;
  --GREEN_BUTTON_DARK: #45a049;
  --RED_BUTTON: #e74c3c;
  --RED_BUTTON_DARK: #c0392b;
  --PURPLE_BUTTON: #8e44ad;
  --PURPLE_BUTTON_DARK: #7d3c98;
  --DISABLED_COLOR: #e0e0e0;

  --BORDER_RADIUS: 0.25rem; /* 4px */
}

/* Адаптация размеров для экранов */
@media (max-width: 768px) {
  :root {
    font-size: 14px; /* Уменьшаем базовый размер шрифта */
  }
}

@media (max-width: 480px) {
  :root {
    font-size: 12px; /* Ещё меньше для телефонов */
  }
}